<template>
  <div class="GroupAmountModal">
    <span class="Title">{{ mixWB('SPECIFY_GROUP_AMOUNT') }}</span>

    <InputField
      name="area"
      type="number"
      :unit="mixWB('M2')"
      :value="area"
      @on-update="onAreaUpdate" />

    <Button
      :text="mixWB('SAVE')"
      :isDisabled="!isAreaValid"
      @button-click="onSaveClick" />
  </div>
</template>

<script>
import InputField from '@/components/FormElements/InputField.vue'
import Button from '@/components/Buttons/Button.vue'

export default {
  name: 'GroupAmountModal',
  props: {
    groupedType: {
      type: Object,
      required: true,
    },
    initialArea: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      area: 0,
    }
  },
  computed: {
    isAreaValid() {
      return this.area > 0
    },
  },
  methods: {
    onAreaUpdate({ value }) {
      this.area = value
    },
    onSaveClick() {
      this.$store.dispatch(
        'setScreeningInterConnectionGroupAmount',
        [{
          id: this.groupedType.groupID,
          m2: this.area,
        }],
      )
      this.$emit('close-dialog')
    },
  },
  components: {
    InputField,
    Button,
  },
  created() {
    this.area = this.initialArea
  },
}
</script>

<style lang="stylus" scoped>
  .GroupAmountModal
    display block

  .Title
    modalTitle()
</style>
