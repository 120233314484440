<template>
  <div class="ScreeningsUpdateAmountsInterconnections">
    <AmountsPageHeader />

    <div class="InnerPage">
      <NextStepLine showTopBorder />

      <div class="ListWrap">
        <div
          class="GroupWrap"
          v-for="groupedType in groupedTypes"
          :key="groupedType.groupID">
          <AmountTypeItem
            v-for="typeItem in groupedType.typeItems"
            :key="typeItem.type.id"
            :type="typeItem.type"
            :hasAmount="false"
            @edit-amount="onEditAmountClick" />
          <div class="GroupArea">
            <span>
              {{ mixWB('GROUP_AREA') }}: {{ getGroupArea(groupedType.groupID) }} {{ mixWB('M2') }}
            </span>
            <div
              class="IconWrap"
              @click="onEditGroupAmountClick(groupedType)">
              <AmountIcon />
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- Dialog -->
    <Dialog
      :isShowing="showAmountDialog"
      :useComponent="modalToUse"
      :componentProps="{
        type: typeToEdit,
        groupedType: groupedTypeToEdit,
        initialArea: initialArea,
      }"
      @close="showAmountDialog = false" />
  </div>
</template>

<script>
import { screeningGroupedTypes } from '@/globals/javascript/_util/grouped-types'
import { mapGetters } from 'vuex'
import AmountIcon from '@/assets/svg/amount.svg?inline'
import AmountsPageHeader from '@/components/ScreeningItems/Amounts/AmountsPageHeader.vue'
import AmountTypeItem from '@/components/ScreeningItems/Amounts/AmountTypeItem.vue'
import AmountsModal from '@/components/ScreeningItems/Amounts/AmountsModal.vue'
import GroupAmountModal from '@/components/ScreeningItems/Amounts/GroupAmountModal.vue'
import Dialog from '@/components/Modals/Dialog.vue'
import NextStepLine from '@/components/ScreeningItems/NextStepLine.vue'

export default {
  name: 'ScreeningsUpdateAmountsInterconnections',
  data() {
    return {
      showAmountDialog: false,
      typeToEdit: undefined,
      groupedTypeToEdit: undefined,
      initialArea: 0,
      modalToUse: AmountsModal,
    }
  },
  computed: {
    ...mapGetters([
      'currentScreeningSelectedFilterUnitID',
      'screeningInterConnections',
      'screeningTypes',
      'currentScreeningData',
    ]),
    groupedTypes() {
      const allGroupedTypes = screeningGroupedTypes({
        interConnections: this.screeningInterConnections,
        screeningTypes: this.screeningTypes,
        unitID: this.currentScreeningSelectedFilterUnitID,
      })

      return allGroupedTypes.filter((x) => x.options.isAmountRequired)
    },
  },
  methods: {
    getGroupArea(groupID) {
      const groupAmount = this.currentScreeningData.interConnectionGroupAmounts.find(
        (x) => x.id === groupID,
      )

      return groupAmount?.m2 || 0
    },
    onEditAmountClick(type) {
      this.showAmountDialog = true
      this.typeToEdit = type
      this.modalToUse = AmountsModal
    },
    onEditGroupAmountClick(groupedType) {
      this.showAmountDialog = true
      this.groupedTypeToEdit = groupedType
      this.modalToUse = GroupAmountModal
      this.initialArea = this.getGroupArea(groupedType.groupID)
    },
  },
  components: {
    AmountIcon,
    AmountsPageHeader,
    AmountTypeItem,
    Dialog,
    NextStepLine,
  },

}
</script>

<style lang="stylus" scoped>
  .ScreeningsUpdateAmountsInterconnections
    pageWrap()

  .InnerPage
    page-wrap-inner-1()

  .GroupWrap
    border 1px solid $color_grey_lighter
    padding 5px
    margin-bottom 20px

  .GroupArea
    display flex
    justify-content space-between
    align-items center
    background-color $color_grey_lightest
    padding 10px
    .IconWrap
      box(40px)
      padding 7.5px
      border-radius 3px
      background-color $color_primary
      box-shadow $box_shadow_1
      cursor pointer
      svg
        fill #fff
</style>
